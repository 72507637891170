export const GET_HOST = "GET_HOST";
export const CREATE_NEW_HOST = "CREATE_NEW_HOST";
export const EDIT_HOST = "EDIT_HOST";
export const DELETE_HOST = "DELETE_HOST";

export const OPEN_HOST_DIALOG = "OPEN_HOST_DIALOG";
export const CLOSE_HOST_DIALOG = "CLOSE_HOST_DIALOG";

export const SET_CREATE_HOST_DONE = "SET_CREATE_HOST_DONE";
export const UNSET_CREATE_HOST_DONE = "UNSET_CREATE_HOST_DONE";

export const SET_UPDATE_HOST_DONE = "SET_UPDATE_HOST_DONE";
export const UNSET_UPDATE_HOST_DONE = "UNSET_UPDATE_HOST_DONE";

export const ENABLE_DISABLE_HOST = "ENABLE_DISABLE_HOST";

export const GET_CURRENT_WEEK_ANALYTIC = "GET_CURRENT_WEEK_ANALYTIC";
export const GET_LAST_WEEK_ANALYTIC = "GET_LAST_WEEK_ANALYTIC";

export const HOST_RECENT_CALL_HISTORY = "HOST_RECENT_CALL_HISTORY";
export const HOST_INCOMING_CALL_HISTORY = "HOST_INCOMING_CALL_HISTORY";

export const EDIT_HOST_COIN = "EDIT_HOST_COIN";

export const UPDATE_COIN_HISTORY = "UPDATE_COIN_HISTORY";

export const GET_TOTAL_RECORD = "GET_TOTAL_RECORD";
