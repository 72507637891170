export const GET_USER = "GET_USER";
export const BLOCK_UNBLOCK_USER = "BLOCK_UNBLOCK_USER";

export const EDIT_USER_COIN = "EDIT_USER_COIN";

export const OPEN_USER_DIALOG = "OPEN_USER_DIALOG";

export const USER_RECHARGE_HISTORY = "USER_RECHARGE_HISTORY";

export const UPDATE_COIN_HISTORY = "UPDATE_COIN_HISTORY";

export const USER_CALL_HISTORY = "USER_CALL_HISTORY";
