export const GET_SETTING = "GET_SETTING";
export const EDIT_SETTING = "EDIT_SETTING";

export const IS_UNDER_MAINTENANCE = "IS_UNDER_MAINTENANCE";

export const IS_WHATSAPP_SUPPORT = "IS_WHATSAPP_SUPPORT";

export const IS_UPI_ACTIVE = "IS_UPI_ACTIVE";

export const IS_PAY_U_MONEY_ACTIVE = "IS_PAY_U_MONEY_ACTIVE";

export const IS_GOOGLE_PAY_ACTIVE = "IS_GOOGLE_PAY_ACTIVE";

export const IS_PROFILE_INTERSTITIAL_ACTIVE = "IS_PROFILE_INTERSTITIAL_ACTIVE";

export const IS_PROFILE_VIDEO_SHOW = "IS_PROFILE_VIDEO_SHOW";
