export const OPEN_LANGUAGE_DIALOG = "OPEN_LANGUAGE_DIALOG";
export const CLOSE_LANGUAGE_DIALOG = "CLOSE_LANGUAGE_DIALOG";

export const GET_LANGUAGE = "GET_LANGUAGE";
export const CREATE_NEW_LANGUAGE = "CREATE_NEW_LANGUAGE";
export const EDIT_LANGUAGE = "EDIT_LANGUAGE";
export const DELETE_LANGUAGE = "DELETE_LANGUAGE";

export const SET_CREATE_LANGUAGE_DONE = "SET_CREATE_LANGUAGE_DONE";
export const UNSET_CREATE_LANGUAGE_DONE = "UNSET_CREATE_LANGUAGE_DONE";

export const SET_UPDATE_LANGUAGE_DONE = "SET_UPDATE_LANGUAGE_DONE";
export const UNSET_UPDATE_LANGUAGE_DONE = "UNSET_UPDATE_LANGUAGE_DONE";

export const GET_TOTAL_RECORD = "GET_TOTAL_RECORD";
