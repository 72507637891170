export const GET_COMPLAIN = "GET_COMPLAIN";
export const OPEN_CLOSE_COMPLAIN = "OPEN_CLOSE_COMPLAIN";

export const OPEN_COMPLAIN_DIALOG = "OPEN_COMPLAIN_DIALOG";
export const CLOSE_COMPLAIN_DIALOG = "CLOSE_COMPLAIN_DIALOG";

export const GET_SOLVED_COMPLAIN = "GET_SOLVED_COMPLAIN";

export const GET_PENDING_DATE_LIST = "GET_PENDING_DATE_LIST";
export const GET_ACCEPTED_DATE_LIST = "GET_ACCEPTED_DATE_LIST";
